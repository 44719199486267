<template>
<div id="import" class="screen text-align-center visibility-hidden faded-show">
  <button @click="router.push('/')" id="import_backBtn" class="btn-back"></button>
  <div class="middle">
    <div class="middle-content">
      <div class="screen-title mt-80">Import seed</div>
      <div class="screen-text mb-10">
        Please login to your wallet by<br />
        entering the seed you wrote<br />
        down when creating the wallet.
      </div>

      <button @click="router.push('/')" id="import_alertBtn" class="btn-lite">I don't have seed</button>

      <div id="importWords">
        <div class="seed">
          <input v-model="seed" type="text" tabindex="1" spellcheck="false" autocomplete="off" />
        </div>
      </div>

      <div class="clear-both">
        <button @click="submit" class="btn-blue screen-btn mt-30 mb-20">
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { useRouter, ref, notify } from '@/logic/functions'
const router = useRouter()

const seed = ref('')

function submit() {
  if (!seed.value.length) return
  if (seed.value.length !== 64) return notify({ emoji: 'X', title: 'Invalid seed', text: 'Seed must be 64 symbols long' })
  localStorage.setItem('seed', seed.value)
  router.push('/wallet')
}
</script>

<style lang="sass" scoped>
</style>